// tslint:disable:no-floating-promises
import { getAppDefinitions } from '@wix/members-area-app-definitions';

import enforceSequentiality from '../enforceSequentiality';
import { toMonitored, log } from '../../utils/monitoring';
import * as state from './applicationState';
import { isApplicationReady } from '../applicationState';
import { addApplications } from '../platform-api/addApplications';
import { removeMembersAreaPage } from '../platform-api/removeMembersAreaPage';
import { asyncFilter } from '../../utils/promises';
import { IntegrationApplication } from '@wix/members-area-integration-kit';
import { createBIService } from '../../utils/bi';
import { getPageByIntegrationApp } from './pages';
import { isMyWishlist } from './myWishlistIntegration';
import { removeLoosePagesOutsideMembersArea } from '../data-fixers/pages';

interface IntegratedAppsMap {
  [appDefId: string]: IntegrationApplication[];
}
const isAddApplication = (app: IntegrationApplication) => app.method === 'addApplication';
const hasNoDependencies = (allApps: IntegrationApplication[]) => (app: IntegrationApplication) =>
  !allApps.some((app2) => app2.pageId === app.pageId);
const isAppInstalled = (editorSDK) => (appDefinitionId) =>
  editorSDK.document.tpa.isApplicationInstalled('', { appDefinitionId });

const maybeAddApplications = async (applications: IntegrationApplication[], editorSDK) => {
  const isReady = await isApplicationReady(editorSDK);
  if (!isReady) {
    console.warn('Members Area installation was corrupted so the integrations pages will not be added');
    log('Skipping addApplications as the application is not ready and probably already deleted');
    return;
  }
  const forceHorizontalLayout = false;
  return toMonitored('editorApi.addApplications', () =>
    addApplications({ editorSDK, applications, forceHorizontalLayout }),
  );
};

const removePage = ({ id, editorSDK }) =>
  toMonitored('editorApi.removeMembersAreaPage', () => removeMembersAreaPage({ editorSDK, id }));

export const registerMembersAreaApps = (applications: IntegrationApplication[], verticalAppDefId: string, editorSDK) =>
  toMonitored('editorApi.registerMembersAreaApps', async () => {
    const applicationDefinitions = await getAppDefinitions({ applications, editorSDK });
    const currentIntegratedAppsMap = state.getAllIntegratedApps();
    state.setIntegratedApps({
      ...currentIntegratedAppsMap,
      [verticalAppDefId]: applicationDefinitions,
    });

    enforceSequentiality(() => removeLoosePagesOutsideMembersArea(editorSDK, applications));
  });

export const installRegisteredApps = async (verticalAppDefId: string, editorSDK) => {
  const biService = await createBIService({ editorSDK });
  biService.verticalTriggeredMaInstallInitiated({ originAppId: verticalAppDefId });

  return enforceSequentiality(() =>
    toMonitored('editorApi.installRegisteredApps', async () => {
      const integrationApps: IntegrationApplication[] = state.getVerticalsApps(verticalAppDefId);
      const integrationAppsToInstall = integrationApps.filter((app) => app.shouldInstallInitially !== false);

      if (integrationAppsToInstall.length > 0) {
        await maybeAddApplications(integrationAppsToInstall, editorSDK);
      }

      biService.verticalTriggeredMaInstallSuccess({ originAppId: verticalAppDefId });
    }),
  );
};

export const handleVerticalDeletion = (verticalAppDefId: string, editorSDK) =>
  enforceSequentiality(() =>
    toMonitored('editorApi.handleVerticalDeletion', async () => {
      const verticalsApps: IntegrationApplication[] = state.getVerticalsApps(verticalAppDefId);
      const allIntegratedAppsMap: IntegratedAppsMap = state.getAllIntegratedApps();
      const installedVerticalIds: string[] = await asyncFilter(
        Object.keys(allIntegratedAppsMap),
        isAppInstalled(editorSDK),
      );
      const appsOfOtherVerticals = installedVerticalIds.reduce<IntegrationApplication[]>(
        (acc, appDefId) => acc.concat(allIntegratedAppsMap[appDefId] ?? []),
        [],
      );
      const integratedAppsToDelete = verticalsApps
        .filter(hasNoDependencies(appsOfOtherVerticals))
        .filter(isAddApplication);

      for (const app of integratedAppsToDelete) {
        const pageToRemove = await getPageByIntegrationApp({ editorSDK, app });
        if (pageToRemove) {
          await removePage({ editorSDK, id: pageToRemove.id });
        }
      }
    }),
  );

export const getRegisteredApps = (editorSDK) =>
  enforceSequentiality(() =>
    toMonitored('editorApi.getRegisteredApps', async () => {
      const allIntegratedAppsMap: IntegratedAppsMap = state.getAllIntegratedApps();
      const registeredVerticalIds = Object.keys(allIntegratedAppsMap);
      const installedVerticalIds: string[] = await asyncFilter(registeredVerticalIds, isAppInstalled(editorSDK));
      const filteredAppsMap: IntegratedAppsMap = await installedVerticalIds.reduce(async (acc, appDefId) => {
        const notInstalledPages = await asyncFilter(
          allIntegratedAppsMap[appDefId],
          async (app) => !(await getPageByIntegrationApp({ editorSDK, app })) && !isMyWishlist(app),
        );
        return { ...(await acc), [appDefId]: notInstalledPages };
      }, Promise.resolve({}));

      return filteredAppsMap;
    }),
  );
