import { logError } from '../utils/monitoring';
import _ from 'lodash';
import * as menus from './wrappers/menus';
import * as pagesGroup from './wrappers/pagesGroup';
import * as pages from './wrappers/pages.ts';
import * as pagesService from './services/pages';
import * as routers from './wrappers/routers';
import * as constants from './constants';

const { APP_TOKEN } = constants;

async function setProtectedPage(editorSDK, pageData) {
  const existingPageData = await editorSDK.pages.data.get(APP_TOKEN, { pageRef: pageData.pageRef });
  if (!_.get(existingPageData, 'pageSecurity.requireLogin')) {
    await pages.updatePageData({
      editorSDK,
      pageRef: pageData.pageRef,
      pageData: { pageSecurity: { requireLogin: true } },
    });
  }
}

// TODO - Maybe this could be replaced with some kind of lock mechanism? WEED-5953
const pagesToConnect = [];
let connecting = false;

/**
 * @deprecated
 * Should be removed along with the 'isManageMemberPagesEnabled' experiment
 */
/* eslint-disable no-unused-vars */
async function connectPageToMembers({
  editorSDK,
  appToken,
  menuIds,
  pageData,
  showInLoginMenu,
  routerConfig,
  appDefinitionId,
  loginMenuTitle,
  urlOverride = null,
  showInMemberMenu = true,
  showInIconsMenu = false,
}) {
  /* eslint-enable no-unused-vars */
  const args = arguments[0];
  const callback = new Promise(function (resolve) {
    pagesToConnect.push({
      resolve,
      args,
    });
  });
  if (!connecting) {
    connecting = true;
    await connectPagesInQueue();
  }
  await Promise.all([callback]);
}

async function connectPagesInQueue() {
  if (pagesToConnect.length) {
    const page = pagesToConnect.pop();
    await connectPage(page.args);
    page.resolve();
    await connectPagesInQueue();
  } else {
    connecting = false;
  }
}

async function createMenuItem({ editorSDK, menuId, linkData }) {
  const appRouters = await routers.getAll(editorSDK);
  const menuItems = await menus.getMenuItems({ editorSDK, menuId });
  const newMenuItem = menus.createNewMenuItem(linkData);
  menuItems.push(newMenuItem);
  appRouters.forEach((router) => {
    return (
      router.config &&
      router.config.patterns &&
      Object.keys(router.config.patterns).forEach((pattern) => {
        const menuItem = menuItems
          .filter((item) => item.link?.routerId === router.id && `/${item.link.innerRoute}` === pattern)
          .pop();
        if (menuItem) {
          menuItem.order = router.config.patterns[pattern].appData.menuOrder || constants.DEFAULT_MENU_ORDER;
        }
      })
    );
  });

  const reorderedItems = _.sortBy(menuItems, (item) => item.order || constants.DEFAULT_MENU_ORDER);
  return await menus.updateMenuItems({ editorSDK, menuId, items: reorderedItems });
}

async function navigateToFirstPrivatePage(editorSDK) {
  const allRouters = await routers.getAll(editorSDK);
  const privateRouter = allRouters.find((router) => router.config.type === 'private');
  const privatePage = privateRouter.pages[0];

  if (!privatePage || !privatePage.pageRef) {
    const error = new Error('Could not retrieve a private page when navigating to first private page');
    logError(error, { extra: { allRouters: JSON.stringify(allRouters) } });
    throw error;
  }

  await pages.navigateToPageRef({ editorSDK, pageRef: privatePage.pageRef });
}

async function connectPage({
  editorSDK,
  menuIds,
  pageData,
  showInLoginMenu,
  routerConfig,
  loginMenuTitle,
  urlOverride = null,
  showInMemberMenu = true,
  showInIconsMenu = false,
}) {
  const { pageRef, pageUriSEO, isPrivate } = pageData;
  const routerRef = await routers.connectPageToRouter({
    editorSDK,
    routerConfig,
    pageRef,
    pageUriSEO,
    isPrivate,
    urlOverride,
  });
  const routerIdPromise = routers.getId(editorSDK, routerRef);
  let innerRoute = urlOverride || pageUriSEO;
  let protectedPagePromise, loginMenuPromise, iconsMenuPromise;

  if (isPrivate) {
    protectedPagePromise = setProtectedPage(editorSDK, pageData);
  } else {
    innerRoute = '{userName}/' + innerRoute;
  }

  const membersMenulinkData = {
    label: pageData.title,
    link: {
      type: 'DynamicPageLink',
      routerId: await routerIdPromise,
      innerRoute: innerRoute,
    },
  };
  if (!showInMemberMenu) {
    membersMenulinkData.isVisible = false;
    membersMenulinkData.isVisibleMobile = false;
  }
  const memberMenuPromise = createMenuItem({ editorSDK, menuId: menuIds.members, linkData: membersMenulinkData });

  if (showInLoginMenu) {
    const loginMenuLinkData = {
      label: loginMenuTitle || pageData.title,
      link: {
        type: 'DynamicPageLink',
        routerId: await routerIdPromise,
        innerRoute: innerRoute,
      },
    };
    loginMenuPromise = createMenuItem({ editorSDK, menuId: menuIds.login, linkData: loginMenuLinkData });
  }
  loginMenuPromise = loginMenuPromise || Promise.resolve();

  if (showInIconsMenu) {
    const iconsMenuLinkData = {
      label: pageData.title,
      link: {
        type: 'DynamicPageLink',
        routerId: await routerIdPromise,
        innerRoute: innerRoute,
      },
      iconRef: { svgId: '2c36dc006cb94853a49daee7e821f642.svg', type: 'VectorImage' },
    };
    iconsMenuPromise = createMenuItem({ editorSDK, menuId: menuIds.icons, linkData: iconsMenuLinkData });
  }
  iconsMenuPromise = iconsMenuPromise || Promise.resolve();

  const pagesGroupPromise = pagesGroup.addPageToGroup(editorSDK, constants.MEMBERS_PAGES_GROUP_NAME, pageRef.id);
  await Promise.all([protectedPagePromise, memberMenuPromise, loginMenuPromise, iconsMenuPromise, pagesGroupPromise]);
}

async function removePageFromMenus({ editorSDK, options }) {
  let { returnValue: removedInnerRoute } = await routers.onRouterPageDelete(
    editorSDK,
    options.pageRole,
    options.pageRef,
  );
  if (!removedInnerRoute) {
    return;
  }
  removedInnerRoute = removedInnerRoute.replace(/\/?(.*)/, '$1');
  await menus.removePatternFromAllMenus({ editorSDK, pattern: removedInnerRoute });
}

async function removePage({ editorSDK, options }) {
  const deleteResult = await routers.onRouterPageDelete(editorSDK, options.pageRole, options.pageRef);
  let { returnValue: removedInnerRoute } = deleteResult;
  if (!removedInnerRoute) {
    return;
  }
  removedInnerRoute = removedInnerRoute.replace(/\/?(.*)/, '$1');
  await menus.removePatternFromAllMenus({ editorSDK, pattern: removedInnerRoute });

  await editorSDK.document.application.reloadManifest();
}

async function isInMembersAreaSubPage(editorSDK) {
  const currentPageRef = await editorSDK.pages.getCurrent();
  const allMAPagesRefs = await pagesService.getAllMembersPagesRefs({ editorSDK });
  return !!allMAPagesRefs.find((pageRef) => pageRef.id === currentPageRef.id);
}

async function navigateToHomePage(editorSDK) {
  const pageRef = await pages.getHomePage({ editorSDK });
  return pages.navigateToPageRef({ editorSDK, pageRef });
}

export {
  removePage,
  navigateToFirstPrivatePage,
  connectPageToMembers,
  createMenuItem,
  isInMembersAreaSubPage,
  navigateToHomePage,
  removePageFromMenus,
};
