import { getCustomPages } from '../../utils/pages';
import { isManageMemberPagesEnabled } from '../../utils/experiments';
import * as actions from './actions';
import * as pages from './pages';
import * as constants from '../constants';
import { shouldUsePlatformisedPagesPanel } from '../services/pages';

const getRouterActions = (t, hasCustomPages) => ({
  showOnAllModes: true,
  default: [
    actions.getAddPageAction(t),
    actions.VIEW_SITE_MEMBERS,
    actions.OPEN_SIGNUP_SETTINGS,
    actions.getAdvancedURLSettingsAction(t),
    hasCustomPages && actions.getDeleteCustomPageAction(t),
    actions.getDeleteMembersAreaAction(t),
  ].filter((a) => !!a),
});

async function createAppManifest(editorSDK, t) {
  const customPages = await getCustomPages(editorSDK);
  const showPlatformisedPagesPanel = await shouldUsePlatformisedPagesPanel({ editorSDK });
  const showManagePagesPagesPanel = await isManageMemberPagesEnabled();
  const hasCustomPages = customPages.length > 0;
  const routerActions = getRouterActions(t, hasCustomPages);

  if (showManagePagesPagesPanel && showPlatformisedPagesPanel) {
    return {
      controllersStageData: {
        members: {
          default: {
            visibility: 'NEVER',
          },
        },
      },
      pages: {
        pageDescriptors: {
          default: { icon: 'membersAction' },
        },
        applicationSettings: {
          default: {
            displayName: t('Pages_Panel_MemberMenu_Title'),
            helpId: constants.MEMBERS_PAGES_PANEL_HELP_ID,
          },
        },
        applicationActions: getRouterActions(t, false),
        pageSettings: {
          default: [pages.PAGE_INFO, pages.PERMISSIONS, pages.SEO],
        },
        pageActions: {
          default: [],
          deletable: [
            {
              title: t('Pages_Panel_MemberMenu_Actions_Page_Delete'),
              icon: 'deleteAction',
              event: 'removePageNew',
            },
          ],
          readOnly: [],
        },
      },
    };
  } else if (showPlatformisedPagesPanel) {
    return {
      controllersStageData: {
        members: {
          default: {
            visibility: 'NEVER',
          },
        },
      },
      pages: {
        pageDescriptors: {
          default: { icon: 'membersAction' },
        },
        applicationSettings: {
          default: {
            displayName: t('Pages_Panel_MemberMenu_Title'),
            helpId: constants.MEMBERS_PAGES_PANEL_HELP_ID,
          },
        },
        applicationActions: routerActions,
        pageSettings: {
          default: [pages.PAGE_INFO, pages.PERMISSIONS, pages.SEO],
        },
        pageActions: {
          default: [],
        },
      },
    };
  }

  return {
    controllersStageData: {
      members: {
        default: {
          visibility: 'NEVER',
        },
      },
    },
    routers: {
      pagesGroups: {
        members: {
          label: t('DYNAMIC_PAGES_APP_TYPE_MEMBERS'),
        },
      },
      routerActions,
      pageSettings: {
        default: [pages.PAGE_INFO, pages.PERMISSIONS, pages.SEO],
      },
      pageActions: {
        default: [],
      },
    },
  };
}

export { createAppManifest };
