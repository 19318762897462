import uuid from 'uuid';
import * as routersWrapper from '../wrappers/routers';

function createPageSEOData(pageTitle, isPrivate) {
  return {
    title: isPrivate ? pageTitle : '{userName} | ' + pageTitle,
    description: '',
    keywords: '',
    noIndex: isPrivate.toString(),
  };
}

async function getMembersAreaRouters(editorSDK) {
  const routers = await routersWrapper.getAll(editorSDK);

  if (routers.length === 0) {
    throw new Error('Could not find both routers in #getMembersAreaRouters');
  }

  const publicRouter = routers.find((r) => r.config.type === 'public');

  if (!publicRouter) {
    throw new Error('Could not find the public router in #getMembersAreaRouters');
  }

  const privateRouter = routers.find((r) => r.config.type === 'private');

  if (!privateRouter) {
    throw new Error('Could not find the private router in #getMembersAreaRouters');
  }

  return { publicRouter, privateRouter };
}

function connectPagesToRouterRef({ editorSDK, pages, pagesUids, routerRef }) {
  const promises = pages.map((page, i) =>
    routersWrapper.connectPageRefToRouter({
      editorSDK,
      pageRef: page.pageData.pageRef,
      pageRoles: [pagesUids[i]],
      routerRef,
    }),
  );
  return Promise.all(promises);
}

function createPatternsAndConfigs({ pages, pagesUids }) {
  return pages.reduce((acc, page, i) => {
    const { title, isPrivate, pageUriSEO } = page.pageData;
    const pageLink = page.urlOverride || pageUriSEO;
    const pattern = isPrivate ? '/' + pageLink : '/{userName}/' + pageLink;
    const { appData, socialHome } = page.routerConfig || {};
    const seoData = createPageSEOData(title, isPrivate);

    acc[pattern] = { socialHome, appData, page: pagesUids[i], seoData, title, pageId: page.routerConfig.pageId };
    return acc;
  }, {});
}

async function connectPagesToRouter({ editorSDK, pages, router }) {
  const pagesUids = new Array(pages.length).fill(null).map(() => uuid.v4());
  const routerRef = await routersWrapper.getRouterRefByPrefix({ editorSDK, prefix: router.prefix });
  const createdRouterPatternsConfig = createPatternsAndConfigs({ pages, pagesUids });
  const oldRouterPatternsConfig = router.config.patterns || {};
  const newRouterPatternsConfig = { ...oldRouterPatternsConfig, ...createdRouterPatternsConfig };
  const newRouterConfig = { ...router.config, patterns: newRouterPatternsConfig };

  return Promise.all([
    connectPagesToRouterRef({ editorSDK, pages, pagesUids, routerRef }),
    routersWrapper.updateRouterConfig({ editorSDK, config: newRouterConfig, routerRef }),
  ]);
}

export { getMembersAreaRouters, connectPagesToRouter };
