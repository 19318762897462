import _ from 'lodash';

import * as constants from '../constants';
import { log } from '../../utils/monitoring';
import * as componentsWrapper from './components';

const { APP_TOKEN } = constants;

async function createController(editorSDK, masterRef) {
  // todo - validate - "there can be only one"
  const compDef = constants.CONTROLLER_COMP_DEF;
  return await editorSDK.components.add(APP_TOKEN, {
    pageRef: masterRef,
    componentDefinition: compDef,
    customId: constants.CONTROLLER_COMP_CUSTOM_ID,
  });
}

async function getController(editorSDK) {
  const controllers = await editorSDK.controllers.listControllers(APP_TOKEN, {});
  return _.head(controllers).controllerRef;
}

async function getAllControllers(editorSDK) {
  return await editorSDK.controllers.listAllControllers();
}

function filterDuplicateControllers(controllerList) {
  const isUniqueController = (ref, idx, arr) =>
    arr.findIndex((ref2) => ref2.controllerRef.id === ref.controllerRef.id) === idx;
  return controllerList.filter(isUniqueController);
}

async function getAllMasterControllers(editorSDK) {
  const allControllersRefs = await getAllControllers(editorSDK);

  const masterControllersRefs = await Promise.all(
    allControllersRefs.map(async ({ controllerRef }) => {
      const isSlave = controllerRef.id.includes('_r_');
      if (isSlave) {
        const templateControllerRef = await editorSDK.components.refComponents.getTemplateComponent('', {
          componentRef: controllerRef,
        });
        return { controllerRef: templateControllerRef };
      } else {
        return { controllerRef: controllerRef };
      }
    }),
  );
  const uniqueMasterControllersRefs = filterDuplicateControllers(masterControllersRefs);

  return uniqueMasterControllersRefs;
}

async function removeConnectedComponents(editorSDK, controllerRef) {
  const componentsRefs = await editorSDK.controllers.listConnectedComponents(APP_TOKEN, controllerRef);
  for (const componentRef of componentsRefs) {
    await editorSDK.components.remove(APP_TOKEN, { componentRef });
  }
}

async function remove(editorSDK, controllerRef) {
  await editorSDK.components.remove(APP_TOKEN, { componentRef: controllerRef.controllerRef });
}

async function wipeOut(editorSDK) {
  const controllersRefs = await getAllMasterControllers(editorSDK);
  for (const controllerRef of controllersRefs) {
    // why don't I see a verticalMenu here?
    // because only verticalMenus currently rendered are returned... Huston we have a problem!!!
    await removeConnectedComponents(editorSDK, controllerRef);
    await remove(editorSDK, controllerRef);
  }

  const appDefIdsToRemove = [constants.PROFILE_WIDGET_APP.appDefinitionId, constants.ALL_MEMBERS_APP_DEF_ID];
  await componentsWrapper.removeComponentsByAppDefIds(editorSDK, appDefIdsToRemove);
}

async function connectToController({
  editorSDK,
  connectToRef,
  controllerRef,
  role,
  connectionConfig = {},
  isPrimary = true,
}) {
  try {
    await editorSDK.controllers.connect(APP_TOKEN, {
      connectToRef,
      controllerRef,
      connectionConfig,
      role,
      isPrimary,
    });
  } catch (e) {
    const tags = { platformMessage: e.toString() };
    const extra = { args: JSON.stringify({ connectToRef, controllerRef, role, connectionConfig, isPrimary }) };
    log('Failed to connect to controller', { tags, extra });
    throw e;
  }
}

export { connectToController, createController, getController, wipeOut };
