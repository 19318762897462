import * as tpa from '../wrappers/tpa';
import * as constants from '../constants';
import * as componentsWrapper from '../wrappers/components';
import { getIsADI } from './applicationState';
import { log } from '../../utils/monitoring';

const isMyAccountLayoutHorizontal = async ({ editorSDK }) => {
  if (getIsADI()) {
    return false;
  }

  try {
    const appData = await tpa.getDataByAppDefId({
      editorSDK,
      appDefinitionId: constants.MY_ACCOUNT_APP_DEF_ID,
    });
    const applicationId = appData?.applicationId;
    if (!applicationId) {
      log('Invalid getDataByAppDefId', {
        extra: {
          appDefinitionId: constants.MY_ACCOUNT_APP_DEF_ID,
          source: 'isMyAccountLayoutHorizontal',
        },
      });
      throw new Error('No applicationId received in getDataByAppDefId');
    }
    const myAccountComps = await tpa.getAllCompsByApplicationId({ editorSDK, applicationId });
    const myAccountCompRef = await componentsWrapper.getById({ editorSDK, id: myAccountComps[0].id });
    const myAccountLayout = await componentsWrapper.getComponentLayout({ editorSDK, componentRef: myAccountCompRef });
    const isHorizontalLayout = myAccountLayout.x < 100;
    return isHorizontalLayout;
  } catch (e) {
    return !getIsADI();
  }
};

export { isMyAccountLayoutHorizontal };
